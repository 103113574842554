import React from "react";
import Doors from "../Doors";
function Door() {
  window.scrollTo(0, 0);
  return (
    <>
      <Doors />
    </>
  );
}

export default Door;
